import {Markdown} from "../util/markdown-utils";
import {getPage} from "../clients/gitlab-client";
import {useEffect, useState} from "react";
import PageNotFound from "../pages/PageNotFound";

export default function StaticPage({urlString}) {
  const [content, setContent] = useState(<p>Loading...</p>)

  useEffect(() => {
    getPage(urlString)
      .then((content) => setContent(<Markdown markdown={content}/>))
      .catch(() => setContent(<PageNotFound missingPath={urlString} />))
  }, [urlString])

  return (
    <>
      {content}
    </>
  )

}