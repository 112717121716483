import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom"
import {titleToUrl} from "../util/url-utils";
// import Comments from "../components/Comments";
import StaticPage from "../components/StaticPage";
import IssuePage from "../components/IssuePage";

export default function Page({ issues }) {
  const { urlString } = useParams()
  const [content, setContent] = useState(<></>)

  useEffect(() => {
    const post = issues.find(issue => titleToUrl(issue.title) === urlString)
    if (post) {
      setContent(<IssuePage issue={post} />)
    } else {
      setContent(<StaticPage urlString={urlString} />)
    }
  }, [issues, urlString])

  return (
    <div className="page">
      {content}
    </div>
  )
}