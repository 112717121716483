import {Markdown} from "../util/markdown-utils";

import {useEffect, useState} from "react";
import {config} from "../util/config";
import {PublishedDate} from "../util/date-utils";

export default function IssuePage({issue}) {
  const [title, setTitle] = useState("")
  const [authors, setAuthors] = useState([])
  const [content, setContent] = useState("")
  const [adjustedMarkdown, setAdjustedMarkdown] = useState("")


  useEffect(() => {
    setTitle(issue.title)
    setAuthors(issue.assignees.map(a => a.name))
    setContent(issue.description)
  }, [issue])

  useEffect(() => {
    setAdjustedMarkdown(content.replace("/uploads", `${config.gitlabBaseUrl}/${config.projectPath}/uploads`))
  }, [content])

  if (!content) {
    return <p>Loading...</p>
  }
  const authorsLine = authors.length > 0 ? <small>by {authors.join(", ")}</small> : null
  return (
    <>
      <div className="post-title">
        <h2>{title}</h2>
        <PublishedDate dateString={issue.due_date} />
      </div>
      {authorsLine}
      <Markdown markdown={adjustedMarkdown} />
      {/*<Comments issue={post} />*/}
    </>
  )

}