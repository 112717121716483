import React from "react";
import {titleToUrl} from "../util/url-utils";
import {Link, useSearchParams} from "react-router-dom";
import {PublishedDate} from "../util/date-utils";

export default function AllArticles({allArticles}) {
  let [searchParams] = useSearchParams()
  const label = searchParams.get("label")
  let issues = allArticles
  let title = "All Articles"
  if(label !== null && label !== undefined && label !== "") {
    issues = issues.filter((i) => i.labels.includes(label))
    title = label
  }

  return (
    <div>
      <h2>{title}</h2>
      {issues.map(article)}
    </div>
  )
}

function article(issue) {

  return (
    <div key={issue.title}>
      <Link to={"/" + titleToUrl(issue.title)}>{issue.title}</Link> - <PublishedDate dateString={issue.due_date} />
    </div>
  )
}