import {config} from "../util/config";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";

export default function Header({toggleSidebar}) {
  const highlights = config.labels.navbarHighlights
    .map(label => <Link key={label} to={`/all-articles?label=${label}`}>{label}</Link>)
  return (
    <div className="header">
      <h1>{config.title}</h1>
      <div className="navbar">
        <Link to="/">Home</Link>
        <Link to="/all-articles">All Articles</Link>
        {highlights}
        <span className="menu-icon">
          <FontAwesomeIcon icon={faBars} onClick={toggleSidebar}/>
        </span>
      </div>
    </div>
  )
}