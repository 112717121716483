import {Link} from "react-router-dom";
import {titleToUrl} from "../util/url-utils";
import {config} from "../util/config";

export default function Features({showSideBar, issues}) {
  const Highlight = ({label, issues}) => {
    const highlightedIssues = issues
      .filter(issue => issue.labels.includes(label))
      .map(issue => <li key={`${issue.id}-${label}`}><Link to={titleToUrl(issue.title)}>{issue.title}</Link></li>)
    return (
      <div key={label}>
        <h4>{label}</h4>
        <ol>
          {highlightedIssues}
        </ol>
      </div>
    )
  }
  const highlights = config.labels.sideBarHighlights.map(label => <Highlight key={label} label={label} issues={issues} /> )
  const recent = issues
    .map(issue => <li key={`${issue.id}-recent`}><Link to={titleToUrl(issue.title)}>{issue.title}</Link></li>)
  return (
    <div className={`features ${showSideBar ? "" : "hide-sidebar"}`}>
      {highlights}
      <div className="recent">
        <h4>Recent</h4>
        <ol>
          {recent}
        </ol>
      </div>
    </div>
  )
}