import React, {useEffect, useState} from "react";
import {HashRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Page from "./pages/Page";
import {getIssues} from "./clients/gitlab-client";
import Header from "./components/Header";
import Features from "./components/Features";
import AllArticles from "./pages/AllArticles";

function App() {
  const [issues, setIssues] = useState([])
  const [showSidebar, setShowSidebar] = useState(false)

  const toggleSidebar = () => {
    setShowSidebar((prev) => !prev)
  }

  useEffect(() => {
    getIssues()
      .then(setIssues)
  }, [])

  return (
    <HashRouter>
      <div className="app">
        <Header toggleSidebar={toggleSidebar}/>
        <div className="page-body">
          <div className="content">
            <Routes>
              <Route index element={<Home/>}/>
              <Route path="/all-articles" element={<AllArticles allArticles={issues}/>}/>
              <Route path=":urlString" element={<Page issues={issues}/>}/>
            </Routes>
          </div>
          <Features showSideBar={showSidebar} issues={issues}/>
        </div>
      </div>
    </HashRouter>
  );
}

export default App;
