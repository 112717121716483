import { config } from "../util/config";
import {Buffer} from "buffer";

function getIssues() {
  return fetch(`${config.gitlabBaseUrl}/api/v4/projects/${config.projectId}/issues?labels=${config.labels.publishedPosts}`)
    .then(r => r.json())
}

function throwExceptionForNotOk(response) {
  if(response.ok) return response
  throw new Error("Page not found")
}
function getPage(path) {
  const encodedPath = encodeURIComponent(`pages/${path}`)
  return fetch(`${config.gitlabBaseUrl}/api/v4/projects/${config.projectId}/repository/files/${encodedPath}?ref=${config.mainBranch}`)
    .then(throwExceptionForNotOk)
    .then(response => response.json())
    .then(data => Buffer.from(data.content, 'base64').toString('ascii'))
}

export {
  getIssues,
  getPage,
}