import {Link} from "react-router-dom";

export default function PageNotFound({missingPath}) {
  return (
    <>
      <h2>That page couldn't be found</h2>
      <p>
        The path {missingPath} doesn't match any of the available pages.
        It might have moved, or been deleted, or there might be something
        else going on.
      </p>
      <p>
        You can try to find what you're looking for on the <Link to="/all-articles">All Articles</Link> page.
      </p>
    </>
  )
}