import {customConfig} from "../customConfig";
import merge, {} from "deepmerge"

const defaultConfig = {
  gitlabBaseUrl: "https://gitlab.com",
  projectId: 33364797,
  projectPath: "jackhandley/gitblab",
  mainBranch: "master",
  title: "GitBlab",
  labels: {
    publishedPosts: "Published",
    sideBarHighlights: [
      "Featured"
    ],
    navbarHighlights: [
      "Dogs"
    ]
  }
}

const overwriteMerge = (destinationArray, sourceArray) => sourceArray

export const config = merge(defaultConfig, customConfig, { arrayMerge: overwriteMerge });